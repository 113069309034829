import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { BaseTitle, LgTitle, SmTitle } from '../elements/Titles';
import { BaseParagraph, XsParagraph } from '../elements/Paragraphs';
import { UnorderedList } from '../elements/Lists';

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Container>
      <Row>
        <Column>
          <LgTitle>Privacy Policy</LgTitle>
          <XsParagraph>
            <strong>Last Updated Date</strong>
          </XsParagraph>
          <XsParagraph>
            This policy was last updated on December 24, 2019.
          </XsParagraph>
        </Column>
      </Row>
      <Row hideMobile="true" textAlign="center">
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" marginBottom="0">
            The full policy
          </SmTitle>
        </Column>
        <Column width="1/3">
          <SmTitle dark="true" marginBottom="0">
            The quick reference
          </SmTitle>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>1. Introduction</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We do our best to keep your personal information safe and secure.
          </BaseParagraph>
          <BaseParagraph>
            By using this site, you are consenting to our use of your personal
            information as set out in this privacy policy. It will change from
            time to time, so keep an eye on this page. We include the revision
            date on the header on the main page for your easy reference.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Welcome to the digital properties of Wise Publishing, Inc., which
            include the Internet sites and software applications that you access
            or download to view Content of Wise Publishing, Inc. (collectively
            "Sites" or each a "Site"). Unless a Wise Publishing, Inc. Site has
            its own Privacy Policy, the collection, use and disclosure of your
            Personal Information will be governed under the terms of this
            privacy policy ("Privacy Policy"). By using our Sites, you consent
            to the collection, use and disclosure of your Personal Information
            under the terms of this Privacy Policy. We take your privacy very
            seriously and we are committed to safeguarding it. We take all
            reasonable steps to protect your privacy and your personal
            information. We do not accept any responsibility for unauthorized
            access, theft or loss of Personal Information beyond our reasonable
            control. Further, please note that communications over the Internet,
            such as emails, are not secure unless they are encrypted.
          </XsParagraph>
          <XsParagraph>
            We may change this policy without notice from time to time to
            reflect changes in legal or regulatory obligations. By visiting our
            Sites, you agree to accept the policy that is in effect at that
            time. Accordingly, you may wish to check this site from time to time
          </XsParagraph>
          <XsParagraph>
            If we make any changes that materially affect personal information
            which we have already collected, we will take reasonable steps to
            notify you.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>2. Definitions</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            The definitions are here. If a term doesn't make sense, look here.
            The most important one is the definition of personal information —
            it's that information about an identifiable individual. It includes
            facts, when combined with other information, can identify an
            individual.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            The following words and expressions have the following meanings
            unless inconsistent with the context:
          </XsParagraph>
          <XsParagraph>
            "<strong>Content</strong>"
          </XsParagraph>
          <XsParagraph>
            means any media that can be rendered on a web page intended for
            consumption by an end-user, including, but not limited to articles,
            quizzes, polls, surveys, videos, pictures, audio and embeds;
          </XsParagraph>
          <XsParagraph>
            "<strong>Cookies or LocalStorage</strong>"
          </XsParagraph>
          <XsParagraph>
            means a small amount of data sent from the server which is then
            stored on your computer's internal storage. This policy uses the
            word Cookies to refer to both methods (Cookies and LocalStorage) of
            storing this information;
          </XsParagraph>
          <XsParagraph>
            "<strong>Personal Information</strong>"
          </XsParagraph>
          <XsParagraph>
            means information about an identifiable individual. Personal
            Information includes facts, which, when combined with other
            information, can identify an individual;
          </XsParagraph>
          <XsParagraph>
            "<strong>PIPEDA</strong>"
          </XsParagraph>
          <XsParagraph>
            <em>
              Personal Information Protection and Electronic Documents Act, SC
              2000
            </em>
            , c. 5 (Canada), as amended from time to time;
          </XsParagraph>
          <XsParagraph>
            "<strong>GDPR</strong>"
          </XsParagraph>
          <XsParagraph>
            <em>General Data Protection Regulation</em>, (EU) 2016/679, as
            amended from time to time;
          </XsParagraph>
          <XsParagraph>
            "<strong>we</strong>", "<strong>us</strong>" or "
            <strong>our</strong>"
          </XsParagraph>
          <XsParagraph>
            Wise Publishing, Inc., a company duly incorporated under the laws of
            the province of Ontario;
          </XsParagraph>
          <XsParagraph>
            "<strong>you</strong>", "<strong>your</strong>"
          </XsParagraph>
          <XsParagraph>
            an individual, company or firm accessing our Site.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>3. How and what we collect</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We collect information from you; that you tell us directly; that
            your internet browser tells us (such as what operating system you
            are using); that your software tells us, (such as demographic
            information and your preferences and interests).
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>We collect Personal Information:</XsParagraph>
          <XsParagraph>from you directly, when you:</XsParagraph>
          <UnorderedList>
            <li>
              register, we collect your username, email address and password;
            </li>
            <li>use the site, your date of birth and geographic location;</li>
            <li>complete surveys and/or respond to offers</li>
            <li>contact us via email, mail or telephone.</li>
          </UnorderedList>
          <XsParagraph>from your internet browser:</XsParagraph>
          <UnorderedList>
            <li>
              type of device, browser and operating system version you use.
            </li>
          </UnorderedList>
          <XsParagraph>
            analytics software installed on our Site such as:
          </XsParagraph>
          <UnorderedList>
            <li>demographic information such as IP address;</li>
            <li>preferences and interests</li>
          </UnorderedList>
          <XsParagraph>
            We do not share Personal Information to third parties except to
            assist us with our internal business operations, as set out in
            paragraph 6.
          </XsParagraph>
          <XsParagraph>
            We do not ask you for, access or track any location-based
            information from your mobile device at any time.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>4. What we do with the information we gather</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            What do we do with your information? It’s all business related; from
            record keeping and training to improving our products and services.
          </BaseParagraph>
          <BaseParagraph>
            We may sell your information collected by Cookies. It is not
            personal information and it is sold in an anonymized format only.
            There is a separate{' '}
            <Link to="/cookies-policy/">
              Cookies policy that you can access here
            </Link>
            .
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
          </XsParagraph>
          <XsParagraph>the information you provide directly:</XsParagraph>
          <UnorderedList>
            <li>internal record keeping;</li>
            <li>
              to respond to your general queries from time to time by mail,
              email or telephone;
            </li>
            <li>
              to periodically send promotional emails containing information
              which we think you may find interesting using the email address
              which you have provided;
            </li>
            <li>
              for our general marketing purposes unless you have notified us to
              the contrary; the information from gathered by Cookies (
              <Link to="/cookies-policy/">
                see here for full Cookies policy
              </Link>
              )
            </li>
            <li>to improve our products and services;</li>
            <li>to optimize the user interface and user experience;</li>
            <li>
              for security purposes to protect your Personal Information held
              and/or processed by us;
            </li>
            <li>
              for training our employees in respect of providing our services to
              you;
            </li>
            <li>
              to prevent, investigate or prosecute activity we think may be
              potentially illegal, unlawful or harmful; and,
            </li>
            <li>
              to enforce our Privacy Policy and/or our Terms of Use or any other
              purpose referenced herein or therein.
            </li>
          </UnorderedList>
          <XsParagraph>
            We do not sell your personally identifiable information. We may sell
            your information in an anonymized format. Your personally
            identifiable information will not be available in that format.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>
            5. Who else gets to see your Personal Information?
          </BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Third parties help us analyze your information, but they are not
            allowed to share it.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            <em>
              Third parties engaged to assist us in providing services to you.
            </em>
          </XsParagraph>
          <XsParagraph>
            We may share Personal Information with third parties, including
            marketing companies engaged to assist us in providing and promoting
            services to you, or to carry out one or more of the purposes
            described in Section 4. These service providers are contractually
            prohibited from using your Personal Information for any purpose
            other than to provide this assistance and are contractually
            obligated to protect Personal Information collected by them on our
            behalf or disclosed to them by us and to comply with the general
            privacy principles described in this policy.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We comply with court orders and laws that require us to disclose
            your personal information.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            <em>
              If a law, regulation, search warrant, subpoena or court order
              legally requires or authorizes us to do so.
            </em>
          </XsParagraph>
          <XsParagraph>
            We reserve the right to disclose Personal Information to a third
            party if a law, regulation, search warrant, subpoena or court order
            legally requires or authorizes us to do so and when we believe in
            good faith that disclosure is necessary to protect our rights,
            protect your safety or the safety of others, investigate fraud, or
            respond to a government request.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            If another company buys Wise Publishing, your personal information
            is part of what they buy.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            <em>In the event of a proposed or actual acquisition.</em>
          </XsParagraph>
          <XsParagraph>
            We reserve the right to disclose and/or transfer Personal
            Information to a third party in the event of a proposed or actual
            purchase, sale (including a liquidation, realization, foreclosure or
            repossession), lease, merger, amalgamation or any other type of
            acquisition, disposal, transfer, conveyance or financing of all or
            any portion of Wise Publishing, Inc. or of any of the business or
            assets or shares of Wise Publishing, Inc. or a division thereof in
            order for you to continue to receive the same products and services
            from the third party. In any of these events, you will be notified
            via email and/or a prominent notice on our Site of any change in
            ownership or uses of your Personal Information, as well as any
            choices you may have regarding your Personal Information.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>
            6. Security, retention and storage of Personal Information
          </BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We take a lot of precautions to keep your personal information
            secure. Through training our employees, contractual provisions to
            protect your personal information with anyone we do business with,
            and technical security measures.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            We are committed to ensuring that your Personal Information is
            secure. In order to prevent loss, theft and unauthorized access,
            copying, use, modification or disclosure, we have put in place
            physical, electronic and managerial procedures to safeguard and help
            secure the Personal Information we collect. All of our employees and
            subcontractors with access to your Personal Information and/or who
            are associated with the processing of that data are contractually
            obliged to protect your Personal Information. Our safeguards include
            firewalls, encryption technology, password protected computers and
            detailed contracts with our employees and contractors.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We keep your information for as long as necessary for the business
            purpose for which it was collected.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            We retain your Personal Information for as long as necessary to
            fulfill the purpose(s) for which it was collected and to comply with
            applicable laws. Your consent to such purposes(s) remains valid
            after termination of our relationship with you.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Information may be processed and stored outside of the jurisdiction
            where you live.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            For the purposes set out in this policy, we or one of our affiliates
            or an unaffiliated service provider may process and store your
            Personal Information outside of the jurisdiction in which you
            reside, and under the laws of those other foreign jurisdictions, in
            certain circumstances courts, law enforcement agencies, regulatory
            agencies or security authorities in those other foreign
            jurisdictions may be entitled to access your Personal Information.
            By providing us with your Personal Information, you are allowing
            your Personal Information to be transferred outside of Canada. For
            more information about our policies and practices related to the use
            of foreign service providers, please contact our Privacy Officer
            using a method described in Section 11, Contact Us.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>7. GDPR</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            If you are a European citizen or within the European Economic Area,
            we have a special Cookie consent for you.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            For the purposes of the GDPR, if you are accessing our Sites from
            within the European Economic Area ("<strong>EEA</strong>") and/or
            you area citizen of the European Union ("<strong>EU</strong>"),
            please be aware that Wise Publishing, Inc. is a Canadian company,
            with its offices in Toronto, Ontario. Please also be aware that data
            centres hosting our cloud-based computing infrastructure may be
            located in the United States.
          </XsParagraph>
          <XsParagraph>
            Further, if we determine you are accessing our Sites from within the
            EEA and/or you are a citizen of the EU, we will ask you to consent
            to our use of Cookies when you first visit our Sites. If you choose
            not to consent, we will need to set a single Cookie to block
            interest-based advertisements and all portions of our Sites that
            legitimately require Cookies for functionality. Please note, if you
            clear Cookies from your browser, your opt-out will not be
            maintained.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>8. CCPA</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            California residents can <Link to="/ccpa-addendum/">look here</Link>{' '}
            for additional information.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            If you are a resident of California, please access the{' '}
            <Link to="/ccpa-addendum/">CCPA Addendum here</Link>.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>9. Links to other websites and advertisers</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            There are links to other websites on our Site, including links to
            advertisers. If you leave this Site via a link, it is the privacy
            policy of the site you are on, not this Site, that applies.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Our Sites may contain links to other websites of interest and to
            advertiser websites. Once you have used these links to leave our
            Sites, you should note that we do not have any control over that
            other website and the privacy practices of other websites. We cannot
            be responsible for the protection and privacy of any information
            which you provide while visiting such websites and such websites are
            not governed by this Privacy Policy. You should exercise caution and
            look at the privacy statement applicable to the website in question.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>10. Controlling your Personal Information</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            You can get information about and control your personal information.
            See # 11 below about how to get in touch to make a request.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You may choose to restrict the collection, use or disclosure of your
            Personal Information in the following ways:
          </XsParagraph>
          <XsParagraph>
            If you have previously agreed to us using your Personal Information,
            you may change your mind at any time by contacting us using a method
            described in Section 11. There may be some limits in our ability to
            delete certain information.
          </XsParagraph>
          <XsParagraph>
            If you wish to subscribe to our newsletter(s), we will use your name
            and email address to send the newsletter to you. You may choose to
            stop receiving our newsletter or marketing emails by following the
            unsubscribe instructions included in those emails or by contacting
            us using a method described in Section 11.
          </XsParagraph>
          <XsParagraph>
            If you have left comments on our Sites, that information may be
            read, collected, and used by others who access them. You can remove
            any of your previously submitted information, by availing yourself
            to the delete option or by contacting us using a method described in
            Section 11. In some cases, we may not be able to remove your
            Personal Information. If we are unable to do so, we will let you
            know and why.
          </XsParagraph>
          <XsParagraph>
            You have the right to request from us information about the
            existence, use and disclosure of your Personal Information. Unless
            prohibited by law, a small fee of $10.00 CAD will be payable as such
            requests will only be answered by registered post to ensure the
            privacy of the recipient. If you would like a copy of the relevant
            information please write to the Privacy Officer, which should be
            accompanied by the $10.00 fee (if applicable). We will provide you
            with the relevant information within thirty (30) days of receipt of
            the fee or otherwise as required by law.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>11. Contact us</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            To contact us about privacy concerns:{' '}
            <a href="mailto:privacy@wisepublishing.com">
              privacy@wisepublishing.com
            </a>
            .
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            If you have any queries about our processing or use of your Personal
            Information or wish to modify or correct or delete your Personal
            Information, you should write, in the first instance to our Privacy
            Officer, Nic Skitt.
          </XsParagraph>
          <XsParagraph>
            <a href="mailto:privacy@wisepublishing.com">
              privacy@wisepublishing.com
            </a>
          </XsParagraph>
          <XsParagraph>
            40 Eglinton Avenue East, Suite 203, Toronto, Ontario, M4P 3A2
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>12. Privacy Commissioner of Canada</BaseTitle>
        </Column>
      </Row>
      <Row marginBottom="144">
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            To contact the Privacy Commissioner of Canada:{' '}
            <a
              href="http://www.priv.gc.ca/index_e.asp"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.priv.gc.ca/index_e.asp
            </a>
            .
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            If you want to learn more about your rights regarding your Personal
            Information, you should contact the Office of the Privacy
            Commissioner of Canada at 1-800-282-1376 or visit their website at{' '}
            <a
              href="http://www.priv.gc.ca/index_e.asp"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.priv.gc.ca/index_e.asp
            </a>
            .
          </XsParagraph>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default PrivacyPolicy;
